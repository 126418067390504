
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    prevText: {
      type: String,
      default: 'Prev',
    },
  },
});
