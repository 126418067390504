
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    dataCheckIcon: String,
    dataUncheckIcon: String,
    name: {
      type: String,
      default: 'toggleCheckbox',
    },
    checked: Boolean,
    color: String,
  },
});
