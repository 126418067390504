
import {
 computed, defineComponent, onMounted, ref,
} from 'vue';
import javascript from './javascript';
import html from './html';
import css from './css';
import bash from './bash';

export default defineComponent({
  data() {
    return {
      copied: false,
    };
  },
  props: {
    value: String,
    lang: {
      type: String,
      required: true,
    },
    dark: Boolean,
  },
  setup(props) {
    const el = ref<null | HTMLElement>(null);

    const replaceWithColoredCode = (codeElements: any, lang: any): any => {
      let newColoredElements = codeElements;
      Object.keys(lang).forEach((key) => {
        newColoredElements = newColoredElements.replace(
          lang[key].exp,
          `<span class="${lang[key].class}">$&</span>`,
        );
      });
      return newColoredElements;
    };

    const colorizeValue = (codeElements: any): any => {
      let result = codeElements;

      if (props.lang.toLowerCase() === 'javascript') {
        result = replaceWithColoredCode(codeElements, javascript);
      } else if (
        props.lang.toLowerCase() === 'html'
        || props.lang.toLowerCase() === 'vue'
      ) {
        result = codeElements.replace('<xmp>', '').replace('</xmp>', '');
        result = codeElements
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/&lt;br&gt;/g, '<br />');
        result = replaceWithColoredCode(result, html);
      } else if (props.lang.toLowerCase() === 'css') {
        result = replaceWithColoredCode(codeElements, css);
      } else if (props.lang.toLowerCase() === 'bash') {
        result = replaceWithColoredCode(codeElements, bash);
      }

      return result;
    };

    onMounted(() => {
      if (!el.value) {
        return;
      }

      el.value.getElementsByTagName('code')[0].innerHTML = colorizeValue(
        el.value.getElementsByTagName('code')[0].innerHTML,
      );
    });

    const classes = computed(() => ({
      dark: props.dark,
    }));

    return {
      classes,
      colorizeValue,
      replaceWithColoredCode,
    };
  },

  watch: {
    value(newVal) {
      this.$el.getElementsByTagName('code')[0].innerHTML = this.colorizeValue(
        newVal,
      );
    },
  },
});
