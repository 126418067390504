
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    hoverEffect: Boolean,
    sidebarClass: String,
  },
  setup() {
    document.body.className = 'sidebar';
  },
});
