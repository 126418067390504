
import { defineComponent } from 'vue';
import {
  withAnimationClasses,
  withAnimationProps,
} from '../../mixins/animations';

export default defineComponent({
  provide() {
    return {
      animated: this.animated,
    };
  },
  props: {
    ...withAnimationProps(),
    animated: Boolean,
    equalHeight: Boolean,
  },
  setup(props) {
    return {
      ...withAnimationClasses(props),
    };
  },
});
