
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  props: {
    content: {
      type: String,
      default: '0',
    },
    position: {
      type: String,
      default: 'right',
    },
    color: String,
  },
  setup(props) {
    const badge = ref<null | HTMLElement>(null);

    onMounted(() => {
      const node = badge.value;
      if (!node) {
        return;
      }

      const spanChild = node.children[0];
      if (spanChild) {
        spanChild.classList.add('badge');
        spanChild.classList.add(props.position);

        if (props.color) {
          spanChild.classList.add(props.color);
        }
        spanChild.setAttribute('data-badge', props.content);
      }
    });

    return {
      badge,
    };
  },
});
