
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    selected: Boolean,
    disabled: Boolean,
  },
});
