
import { withAnimationClasses, withAnimationProps } from '@/mixins/animations';
import { withTooltipClasses, withTooltipProps } from '@/mixins/tooltip';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    ...withAnimationProps(),
    ...withTooltipProps(),
    right: Boolean,
    left: Boolean,
  },
  setup(props) {
    return {
      ...withAnimationClasses(props),
      ...withTooltipClasses(props),
    };
  },
});
