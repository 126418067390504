
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: 'my-radio-btn',
    },
    name: {
      type: String,
      default: 'radio-btn',
    },
    checked: Boolean,
    color: String,
  },
});
