
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    size: String,
    position: String,
    mode: String,
  },
});
