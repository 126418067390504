
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    short: Boolean,
    vertical: Boolean,
    content: String,
  },
});
