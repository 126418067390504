
import { defineComponent, ref } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: 'sb-dropdown',
    },
    showArrow: Boolean,
  },
  setup() {
    const dropdownClicked = ref<boolean>(false);

    return {
      dropdownClicked,
    };
  },
});
