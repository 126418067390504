
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: 'Coming Soon',
    },
    subtitle: {
      type: String,
      default: 'We are currently working on this segment! :)',
    },
  },
});
