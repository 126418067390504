
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    nextText: {
      type: String,
      default: 'Next',
    },
  },
});
