
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    defaultValue: {
      type: String,
      default: '#000',
    },
  },
});
