
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    src: String,
    customBody: Boolean,
    fullscreen: Boolean,
    disableParallax: Boolean,
  },
  setup(props) {
    const styleImage = computed(() => (props.src != null
        ? {
            backgroundImage: `url(${props.src})`,
          }
        : {}));

    return {
      styleImage,
    };
  },
});
