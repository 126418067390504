
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    dropdown: Boolean,
    selected: Boolean,
  },
});
