
import {
 ref, defineComponent, computed, watch,
} from 'vue';
import {
  withAnimationClasses,
  withAnimationProps,
} from '../../mixins/animations';

export default defineComponent({
  props: {
    ...withAnimationProps(),
    value: Boolean,
    color: String,
    position: {
      type: String,
      default: 'topCenter',
    },
    timeout: {
      type: Number,
      default: 3000,
    },
  },
  setup(props) {
    const isActive = ref<boolean>(false);
    const snackbar = ref<HTMLElement | null>(null);

    const startTimeout = (): void => {
      if (!isActive.value && snackbar.value) {
        snackbar.value.classList.add('show');
        isActive.value = true;

        setTimeout(() => {
          isActive.value = false;
          if (snackbar.value) {
            snackbar.value.classList.remove('show');
          }
        }, props.timeout);
      }
    };

    const value = ref(props.value);
    watch(value, () => {
      startTimeout();
    });

    const getPosition = computed(() => {
      const chars = props.position.split(/(?=[A-Z])/);
      return `${chars[0]}-${chars[1].toLowerCase()}`;
    });

    return {
      ...withAnimationClasses(props),
      getPosition,
      isActive,
      snackbar,
    };
  },
});
