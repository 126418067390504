
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: 'input-checkbox',
    },
    color: String,
    checked: Boolean,
  },
});
