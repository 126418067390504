
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    closeTarget: {
      type: String,
      default: '#target',
    },
    mId: {
      type: String,
      default: 'modal',
    },
    applyDefaultCloseIcon: Boolean,
    animation: {
      type: String,
      validator: (value: string) => ['dropdown', 'zoom-in', 'zoom-out'].indexOf(value) !== -1,
    },
    size: {
      type: String,
      validator: (value: string) => ['small', 'normal', 'large'].indexOf(value) !== -1,
    },
  },
  setup(props) {
    const animationString = computed((): string => {
      if (!props.animation) return '';

      const chars = props.animation.split(/(?=[A-Z])/);
      if (chars.length < 2) {
        return props.animation;
      }
      return `${chars[0]}-${chars[1].toLowerCase()}`;
    });

    return {
      animationString,
    };
  },
});
