
import { defineComponent } from 'vue';

export default defineComponent({
  inject: ['animated'],

  provide() {
    return {
      cardImage: this.image,
    };
  },

  props: {
    image: String,
  },
});
