
import { computed, defineComponent } from 'vue';
import {
  withAnimationClasses,
  withAnimationProps,
} from '../../mixins/animations';
import { withTooltipClasses, withTooltipProps } from '../../mixins/tooltip';

export default defineComponent({
  props: {
    ...withAnimationProps(),
    ...withTooltipProps(),
    closable: Boolean,
    deletable: Boolean,
    size: String,
    color: String,
    rounded: Boolean,
  },
  setup(props) {
    const classes = computed(() => [
      withTooltipClasses(props).tooltipClasses,
      withAnimationClasses(props).animationClasses,
      'tag',
      props.size ? `tag--${props.size}` : null,
      props.color ? `tag--${props.color}` : null,
      {
        'tag--rounded': props.rounded,
        'tag__close-btn': props.closable,
      },
    ]);

    return {
      classes,
    };
  },
});
