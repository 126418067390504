
import { defineComponent } from 'vue';
import {
  withAnimationClasses,
  withAnimationProps,
} from '../../mixins/animations';

export default defineComponent({
  props: {
    ...withAnimationProps(),
    src: String,
    text: String,
    padded: Boolean,
    size: {
      type: String,
      validator: (value: string) => ['xsmall', 'small', 'large', 'xlarge'].indexOf(value) !== -1,
    },
  },
  setup(props) {
    return {
      ...withAnimationClasses(props),
    };
  },
});
