
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    c: String,
    o: String,
    w: String,
    h: String,
    dynamicOffset: String,
    fluid: Boolean,
    ignoreScreen: Boolean,
  },
});
